var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("h1", [_vm._v("フィードバック")]),
          _c("p", [
            _vm._v(
              "お問い合わせの内容をご確認いただき「送信」ボタンをクリックしてください。"
            )
          ]),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-group" }, [
            _c("div", [_vm._v("内容")]),
            _c("div", [_vm._v(_vm._s(_vm.kind))])
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("div", [_vm._v("本文")]),
            _c("div", { attrs: { id: "Inquiry" } }, [
              _vm._v(_vm._s(_vm.Inquiry))
            ])
          ]),
          _c("input", {
            staticClass: "btn btn-primary",
            attrs: { type: "button", value: "送信する" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.exec1($event)
              }
            }
          }),
          _c("input", {
            staticClass: "btn btn-secondary ml-5",
            attrs: { type: "button", value: "修正する" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.exec2($event)
              }
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-6
          h1 フィードバック
          p お問い合わせの内容をご確認いただき「送信」ボタンをクリックしてください。
          .alert.alert-danger(v-if="message") {{ message }}
          .form-group
            div 内容
            div {{ kind }}
          .form-group
            div 本文
            div#Inquiry {{ Inquiry }}
          input.btn.btn-primary(type="button" value="送信する" @click.prevent="exec1")
          input.btn.btn-secondary.ml-5(type="button" value="修正する" @click.prevent="exec2")
</template>

<style lang="stylus" scoped>
#Inquiry
  white-space pre-wrap
  word-wrap break-word

</style>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      kind: this.$route.params.kind,
      Inquiry: this.$route.params.Inquiry,
      message: '',
      isDisabled: false,
    }
  },
  created() {
    if(!this.kind) {
      this.$router.push('/feed_back/input') //ページ移動
      return
    }
  },
  methods: {
    exec1() {
      const url = process.env.VUE_APP_API_GATEWAY + '/api-feedback'
      const params = {
        kind: this.kind,
        body: this.Inquiry
      }
      // 内容送信
      axios.post(url, params, {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
        .then(res => {
          // 正常終了時の処理
          this.$router.push('/feed_back/complete')
        })
        .catch(err => {
          // エラー時の処理
          console.log(err)
        })
    },
    exec2(e) {
      this.$router.push({ name: 'feed_back_input', params: {kind: this.kind, Inquiry: this.Inquiry}}) //ページ移動
    }
  }
}
</script>